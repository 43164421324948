import React from 'react'
import { Link } from 'gatsby'

const BlogPage = () => (
  <>
      <h1>WILKOMMEN</h1>
      <Link to="/page-2/">Go to page 2</Link>
  </>
)

export default BlogPage
